<template>
  <div>
    <div v-if="documentExists">
      <v-container>
        <v-dialog v-model="upgradeDialog" scrollable max-width="900px">
          <v-card class="px-5 py-3">
            <v-card-title class="headline justify-center"
              >Upgrade von {{ fromUpgade }} zu
              {{ toUpgrade.bezeichnung }} duchführen?</v-card-title
            >
            <v-card-text></v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary lighten-1 px-8"
                @click="upgradeDocument(toUpgrade)"
                >UPGRADE</v-btn
              >
              <v-btn color="error" text @click="upgradeDialog = false"
                >Abbrechen</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <SiteHeaderComponent :header="siteTitle" />
        <h4 class="text-center mb-10">Status: {{ document.state }}</h4>

        <div class="text-center mb-8">
          <v-btn
            outlined
            class="mr-3"
            @click="printDocument"
            :loading="document.printLoading"
          >
            <v-icon>mdi-printer</v-icon>
            Drucken
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                :disabled="
                  possibleUpgrades == null || possibleUpgrades.length == 0
                "
              >
                Upgrade
                <v-icon class="ml-2">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="possibleUpgrades.length == 0">
                <v-list-item-title
                  >Keine Upgrades für diesen Dokumenttyp
                  verfügbar!</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                v-for="(item, index) in possibleUpgrades"
                :key="index"
                @click="upgradeDocumentDialog(item)"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-card
          class="col-lg-6 col-12 rounded-lg mb-3 mx-auto pa-0"
          elevation="0"
        >
          <v-card-title class="border-bottom-primary-3 mb-6 py-2">
            <h4 class="font-weight-bold text-upercase text-break ">
              <span
                v-if="
                  address.firstname == undefined &&
                    address.lastname == undefined
                "
                >{{ address.name }}</span
              >
              <span v-else>{{
                address.firstname + ' ' + address.lastname
              }}</span>
            </h4>
          </v-card-title>

          <v-card-text class="mb-3">
            <v-row class="mx-0">
              <v-col class="pa-0"> Straße: </v-col>
              <v-col class="pa-0">
                {{ address.address.street }}
              </v-col>
            </v-row>
            <v-row class="mx-0">
              <v-col class="pa-0"> Ort: </v-col>
              <v-col class="pa-0">
                {{ address.address.zip }} {{ address.address.city }}
              </v-col>
            </v-row>
            <v-row class="mx-0">
              <v-col class="pa-0"> Land: </v-col>
              <v-col class="pa-0">
                <span v-if="address.address.country.lfdnr == 1"
                  >{{ 'Austria' }}
                </span>
                <span v-else-if="address.address.country.lfdnr == 15"
                  >{{ 'Germany' }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card :loading="loading" class="mx-auto my-12" elevation="0">
          <!--Ladebalken-->
          <template slot="progress">
            <v-progress-linear
              color="primary"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>

          <!--Dokumenttitel-->

          <v-card-title class="border-bottom-primary-3">
            Positionen
          </v-card-title>

          <!--Themenüberschrift-->
          <v-container class="pb-4">
            <!--Suchleiste-->
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Suche"
              outlined
              hide-details
            ></v-text-field>
          </v-container>

          <!--Positionen Table-->
          <v-data-table
            :headers="headers"
            :items="document.positions"
            class="elevation-0"
            :search="search"
          >
            <template v-slot:item.deliveryPrice="{ item }">
              <span>{{ formatCurrency(item.deliveryPrice) }}</span>
            </template>
            <template v-slot:item.totalPrice="{ item }">
              <span>{{ formatCurrency(item.totalPrice) }}</span>
            </template>
          </v-data-table>
        </v-card>

        <v-card class="mb-12" elevation="1">
          <v-card-title class="border-bottom-primary-3"
            >Interaktionen</v-card-title
          >

          <!--Interaktionen-->

          <InteractionsTable
            class="elevation-0"
            :lfdnr="lfdnr"
            :wf_cid="wf_cid"
          />
        </v-card>

        <!-- Success Snackbar -->
        <v-snackbar v-model="SuccessSnackbar">
          Upgrade erfolgreich durchgeführt!

          <template v-slot:action="{ attrs }">
            <v-btn
              color="green darken-1"
              text
              v-bind="attrs"
              @click="openUpgradedDocument()"
            >
              Dokument ansehen
            </v-btn>
          </template>
        </v-snackbar>
        <template>
          <ErrorDialog
            :failMessage="failMessage"
            :opened="errorDialog"
            @closeDialog="errorDialog = false"
          />
        </template>
      </v-container>
    </div>
    <v-container v-if="!documentExists">
      <v-card class="pa-5" style="border-radius: 15px;">
        <v-layout column align-center justify-center fill-height>
          <v-flex xs12 class="text-center mb-4">
            <h3>
              Das gesuchte Dokument konnte nicht gefunden werden
            </h3>
          </v-flex>
          <v-flex xs12>
            <v-img
              class="mx-auto my-auto"
              src="@/assets/svg/undraw_warning_re_eoyh.svg"
              max-width="350px"
              contain
            ></v-img>
          </v-flex>
        </v-layout>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue'
import InteractionsTable from '@/components/crm/addresses/InteractionsTable.vue'
import currencyFormatService from '@/services/formatters/currencyFormatService.js'
import documentService from '@/services/crm/documentService.js'
import ErrorDialog from '@/components/core/ErrorDialog.vue'
import addressService from '@/services/crm/addressService.js'

//https://diegoazh.github.io/gmap-vue/

export default {
  components: {
    SiteHeaderComponent,
    InteractionsTable,
    ErrorDialog
  },
  data: () => ({
    address: {
      key: {
        lfdnr: '',
        firma: ''
      },
      address: {
        street: '',
        zip: '',
        country: {
          firma: '',
          sprache: '',
          lfdnr: ''
        }
      }
    },
    document: {
      name: '',
      nr: '',
      date: '',
      type: '',
      shortType: '',
      state: '',
      wf_cid: '',
      printLoading: false
    },
    search: '',
    loading: true,
    upgradeDialog: false,
    possibleUpgrades: [],
    fromUpgade: '',
    toUpgrade: '',
    SuccessSnackbar: false,
    upgradedDocument: {},
    documentExists: true,

    headers: [
      { text: 'Artikel', value: 'article' },
      { text: 'Lieferpreis', value: 'deliveryPrice', align: 'end' },
      { text: 'Gesamtpreis', value: 'totalPrice', align: 'end' },
      { text: 'SM', value: 'sm', align: 'end' },
      { text: 'Text', value: 'positionText', align: 'end' },
      { text: 'Menge', value: 'quantity', align: 'end' },
      { text: 'LMenge', value: 'lQuantity', align: 'end' },
      { text: 'VMenge', value: 'vQuantity', align: 'end' },
      { text: 'EH', value: 'unit', align: 'end' },
      { text: 'Status', value: 'state', align: 'end' }
    ],

    failMessage: '',
    errorDialog: false
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    wf_cid: {
      type: String,
      required: true
    }
  },
  methods: {
    init() {
      this.documentExists = true

      this.$store.dispatch('ensureAvailableAppsLoaded')
      this.loadDocument()
      this.loadAddress()
      this.getPossibleUpgrades()

      if (this.$refs.myMap != null)
        this.$nextTick(() => {
          this.$refs.myMap.mapObject.ANY_LEAFLET_MAP_METHOD()
        })
    },
    openUpgradedDocument() {
      this.$router
        .replace({
          path: `/crm/addresses/${this.upgradedDocument.kdnr}/documents/${this.upgradedDocument.wfCid}`
        })
        .then(() => window.location.reload())
    },
    async loadDocument() {
      await axios
        .get(`/v1/crm/documents/${this.wf_cid}`)
        .then(response => {
          response.data.printLoading = false
          this.document = response.data
          this.document.name = `${this.document.shortType} ${this.document.nr}`
          if (response.status == '404') {
            this.documentExists = false
          }
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.documentExists = false
        })
    },
    loadAddress() {
      addressService
        .getAddress(this.lfdnr)
        .then(response => {
          console.warn(response)
          if (response.status !== 200) {
            this.addressExists = 'false'
          } else {
            this.addressExists = 'true'
          }
          this.address = response.data
          this.loading = false
          this.addressTypeName = this.address.addressTypes
        })
        .catch(error => {
          console.error(error)
          this.loading = false
        })
    },
    addressTypesIcons(item) {
      return addressService.getAddressTypeDetailed(item).icon
    },
    addressTypesNames(item) {
      //this.adressTypeName.push(addressService.getAddressTypeDetailed(item).name)
      return addressService.getAddressTypeDetailed(item).name
    },
    printDocument() {
      documentService.printDocument(this.document)
    },
    formatCurrency(value) {
      return currencyFormatService.formatEuro(value)
    },
    upgradeOffer() {
      this.upgradeDialog = true
    },
    async getPossibleUpgrades() {
      if (this.$store.getters.userHasApp('LBA-Business')) {
        await axios
          .get(`/v1/offer/${this.wf_cid}/availableUpgrades`)
          .then(response => {
            console.log(response.data)
            if (response.status == 200) {
              this.possibleUpgrades = response.data
            } else {
              this.failMessage =
                'Beim Laden der möglichen Upgrades ist ein Fehler aufgetreten!'
              this.error = true
            }
          })
      }
    },
    upgradeDocumentDialog(upgradeType) {
      this.fromUpgade = this.document.type
      this.toUpgrade = upgradeType
      this.upgradeDialog = true
    },
    upgradeDocument(upgrade) {
      axios
        .patch(`/v1/business/document/${this.document.wf_cid}/upgrade`, upgrade)
        .then(res => {
          if (res.status == 200) {
            this.upgradedDocument = res.data
            this.SuccessSnackbar = true
            this.getPossibleUpgrades()
          }
        })
      this.upgradeDialog = false
    }
  },
  computed: {
    siteTitle() {
      return this.document.name
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    '$route.params': 'init'
  }
}
</script>

<style></style>
